import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  margin: 1rem auto;
  width: 90%;
`;

export const ContainerHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
`;

export const ContainerTitle = styled.h1`
  font-size: 4rem;
  color: #13304f;

  ${media.lessThan('small')`
    font-size: 3rem;
  `}
`;
