import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

import {
  Container,
  ContainerHeader,
  ContainerTitle,
} from '../../components/Container/styled';

import cardImage from '../../assets/images/card.png';

import * as S from './styled';

export default function About() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <Helmet>
        <title>Sobre | Dr. Opera</title>
        <meta
          name="description"
          content="Saiba mais sobre o cartão multibenefícios Dr. Opera e conheça um pouco mais sobre a nossa história."
        />
      </Helmet>

      <ContainerHeader>
        <ContainerTitle>Quem somos</ContainerTitle>
      </ContainerHeader>

      <S.AboutContent>
        <S.AboutDescription>
          O <S.AboutHighlight>Dr. Opera</S.AboutHighlight> entra no mercado com
          um segmento revolucionário, disponibilizando ao associado a
          possibilidade de ter acesso na área de saúde, com descontos reais e
          diferenciados, além de acesso ao lazer familiar e comércio geral,
          através do serviço de intermediação. Sob o cnpj 35.297.747/0001-38,
          chegamos não só na microrregião de Irecê-BA, com média de 72.967 mil
          habitantes, como também abrangemos toda a macrorregião (200km).
        </S.AboutDescription>

        <S.AboutImageWrapper>
          <S.AboutImageDescription
            src={cardImage}
            alt="Cartão Multibenefícios Dr. Opera"
          />
        </S.AboutImageWrapper>

        <S.AboutDescription>
          Apesar de nova na idade, o{' '}
          <S.AboutHighlight>Dr. Opera</S.AboutHighlight> conta com uma gestão
          qualificada, experiente no setor, vendas PAP e call center a mais de
          07 anos, unido a grande força de parceiros credenciados, nomes como o
          cirurgião Dr. Amauri Lucena e o cirurgião Dr. Rafael Barreto,
          parceiros do <S.AboutHighlight>Dr. Opera</S.AboutHighlight> através do
          HOSPITAL DE CIRURGIA MULTICLIN e CLÍNICA SIM respectivamente, dão
          credibilidade aos produtos e serviços oferecidos por nós.
        </S.AboutDescription>

        <S.AboutDescription>
          Além de todas as vantagens oferecidas EXCLUSIVAMENTE para os
          associados do{' '}
          <S.AboutHighlight>Cartão Multibenefícios Dr. Opera</S.AboutHighlight>{' '}
          (<S.AboutLink to="/beneficios">conheça os benefícios</S.AboutLink>),
          disponibilizamos um mix contendo seguro de vida, sorteios semanais
          pela loteria federal e uma assistência funeral abrangendo o titular,
          cônjuge e filhos com idade de até 21 anos.
        </S.AboutDescription>
      </S.AboutContent>
    </Container>
  );
}
