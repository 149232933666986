import React from 'react';

import * as S from './styled';

export default function Details() {
  return (
    <S.DetailsWrapper>
      <S.DetailsContent>
        <S.DetailsTitleWrapper>
          <S.DetailsTitle>Descontos exclusivos</S.DetailsTitle>
        </S.DetailsTitleWrapper>

        <S.DetailsDescription>
          O <S.DetailsLink to="/">Dr. Opera</S.DetailsLink> disponibiliza ao
          associado a possibilidade de ter acesso na área de saúde,{' '}
          <S.DetailsHighlight>
            descontos reais e diferenciados
          </S.DetailsHighlight>
          , além do acesso ao lazer familiar e comércio geral, através da ampla
          rede de parceiros conveniados.
        </S.DetailsDescription>

        <S.DetailsDescription>
          <S.DetailsHighlight>Descontos que chegam a 60%</S.DetailsHighlight> em
          consultas de inúmeras especialidades, como também em cirurgias
          eletivas. Exclusivo para associados do{' '}
          <S.DetailsHighlight>
            Cartão Multibenefícios Dr. Opera!
          </S.DetailsHighlight>
        </S.DetailsDescription>

        <S.DetailsDescription>
          Exemplo: Uma cirurgia eletiva tabelada na região com média de
          R$8.000,00 (oito mil reais), o associado{' '}
          <S.DetailsLink to="/">Dr. Opera</S.DetailsLink> pagará apenas
          R$3.200,00 (três mil e duzentos reais).
        </S.DetailsDescription>

        <S.DetailsButtonWrapper>
          <S.DetailsButtonCTA to="/beneficios">
            Conheça as vantagens
          </S.DetailsButtonCTA>
        </S.DetailsButtonWrapper>
      </S.DetailsContent>
    </S.DetailsWrapper>
  );
}
