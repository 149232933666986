import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

export default function Location({ title }) {
  return (
    <S.LocationWrapper>
      <S.LocationTitleWrapper>
        <S.LocationTitle>{title}</S.LocationTitle>
      </S.LocationTitleWrapper>
      <iframe
        title="Localização"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3912.4379609839743!2d-41.851822685634154!3d-11.3026650919657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x76ed4a57cfe7a73%3A0xf3e811c1777b9594!2sAv.%20Adolfo%20Moitinho%2C%20664%2C%20Irec%C3%AA%20-%20BA%2C%2044900-000!5e0!3m2!1spt-BR!2sbr!4v1573672758394!5m2!1spt-BR!2sbr"
        width="100%"
        height="400px"
        frameBorder="0"
        allowFullScreen
      />
    </S.LocationWrapper>
  );
}

Location.defaultProps = {
  title: '',
};

Location.propTypes = {
  title: PropTypes.string,
};
