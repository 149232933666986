import React from 'react';

import * as S from './styled';

export default function CallMe() {
  return (
    <S.CallMeWrapper>
      <S.CallMeContent>
        <S.CallMeDetails>
          <S.CallMeDescription>
            Ligue agora mesmo e peça já o seu:
          </S.CallMeDescription>
          <S.CallMePhone>(74)3641-3559 </S.CallMePhone>
          <S.CallMePhone href="https://api.whatsapp.com/send?phone=5574999417660&text=Quero%20mais%20informações%20sobre%20o%20Cartão%20Dr.%20Opera.">
            (74)9.9941-7660{' '}
          </S.CallMePhone>
          <S.CallMePhone href="https://api.whatsapp.com/send?phone=5574999420463&text=Quero%20mais%20informações%20sobre%20o%20Cartão%20Dr.%20Opera.">
            (74)9.9942-0463
          </S.CallMePhone>
        </S.CallMeDetails>
      </S.CallMeContent>
    </S.CallMeWrapper>
  );
}
