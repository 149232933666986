import React from 'react';

import * as S from './styled';

export default function Footer() {
  return (
    <S.FooterWrapper>
      <S.FooterMenu>
        <S.FooterMenuLink to="/" exact>
          Início
        </S.FooterMenuLink>
        <S.FooterMenuLink to="/quem-somos">Sobre</S.FooterMenuLink>
        <S.FooterMenuLink to="/beneficios">Benefícios</S.FooterMenuLink>
        <S.FooterMenuLink to="/nossos-parceiros">Parceiros</S.FooterMenuLink>
        <S.FooterMenuLink to="/fale-conosco">Contato</S.FooterMenuLink>
      </S.FooterMenu>

      <S.FooterCopyright>
        Copyright © <S.FooterLink to="/">Dr. Opera</S.FooterLink>. Todos os
        direitos reservados.
      </S.FooterCopyright>
      <S.FooterAddress>
        Endereço: Av. Adolfo Moitinho, 664 - Centro - Irecê - BA
      </S.FooterAddress>
      <S.FooterDocCompany>
        CNPJ: 35.297.747/0001-38 &bull; (74) 3641-3559
      </S.FooterDocCompany>
    </S.FooterWrapper>
  );
}
