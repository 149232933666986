import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import { darken } from 'polished';

export const DetailsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  width: 100%;
  margin: 3rem 0 0;
`;

export const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

export const DetailsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailsTitle = styled.h2`
  font-size: 4rem;
  color: #061943;

  ${media.lessThan('small')`
    font-size: 2.5rem;
  `}
`;

export const DetailsDescription = styled.p`
  margin: 1.5rem 0;
  font-size: 2rem;
  color: #333;
  line-height: 1.3;

  ${media.lessThan('small')`
    font-size: 1.2rem;
  `}
`;

export const DetailsHighlight = styled.span`
  color: #222;
  font-weight: bold;
`;

export const DetailsLink = styled(Link)`
  color: var(--highlight);
  font-weight: bold;
`;

export const DetailsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailsButtonCTA = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 24rem;
  background: #f53859;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 3rem;
  padding: 1.5rem 3rem;
  transition: background 0.2s;
  margin-top: 4rem;

  &:hover {
    background: ${darken(0.08, '#f53859')};
  }

  ${media.lessThan('medium')`
    font-size: 1.4rem;
    height: 4rem;
    width: 21rem;
  `}
`;
