import styled from 'styled-components';
import media from 'styled-media-query';

export const HomeWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 0.5rem;
`;

export const HomeLogo = styled.img`
  width: 100%;
  max-width: 40rem;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 20rem;
  `}
`;

export const HomeDescription = styled.p`
  font-size: 2rem;
  color: var(--text);
  margin-top: 2rem;
  padding: 0 1rem;

  ${media.lessThan('small')`
    font-size: 1rem;
  `}

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`;
