import styled from 'styled-components';
import media from 'styled-media-query';

import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';
import { Map } from 'styled-icons/boxicons-solid/Map';
import { AlternateEmail } from 'styled-icons/material/AlternateEmail';

export const ContactWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 3rem 0 0;
  width: 100%;
`;

export const ContactColumn = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95%;
  background: #f3f3f3;
  border-radius: 0.4rem;
  margin: 2rem auto;
  padding: 3rem 0;

  ${media.lessThan('medium')`
    flex-direction: column;
    justify-content: center;
  `}
`;

export const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.lessThan('medium')`
    &:not(:first-child) {
      margin-top: 3rem;
    }
  `}
`;

export const ContactTitle = styled.h2`
  font-size: 2.2rem;
  font-weight: bold;
  color: #061943;

  ${media.lessThan('medium')`
    font-size: 1.8rem;
  `}
`;

export const ContactDescription = styled.p`
  font-size: 1.6rem;
  color: #333;
  margin-top: 1rem;

  a {
    font-size: 1.6rem;
    color: #333;
    text-decoration: none;

    &:hover {
      color: var(--highlight);
    }
  }

  ${media.lessThan('medium')`
    font-size: 1.3rem;
  `}
`;

export const ContactAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

export const ContactEmail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

export const ContactText = styled.p`
  font-size: 1.6rem;
  color: #333;
`;

export const WhatsappIcon = styled(Whatsapp)`
  margin-bottom: 1rem;
`;

export const MapIcon = styled(Map)`
  margin-bottom: 1rem;
`;

export const EmailIcon = styled(AlternateEmail)`
  margin-bottom: 1rem;
`;
