import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';

import Banner from './Banner';
import Featured from './Featured';
import Details from './Details';
import CallMe from './CallMe';

import * as S from './styled';
import Location from '../../components/Location';

export default function Home() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <S.HomeWrapper>
      <Helmet>
        <title>Dr. Opera | Seu Cartão Multibenefícios</title>
        <meta
          name="description"
          content="Adquira o cartão multibenefícios do Dr. Opera e obtenha descontos exclusivos na ampla rede de parceiros."
        />
      </Helmet>

      <Banner />

      <Fade bottom>
        <Featured />
      </Fade>

      <Fade bottom>
        <Details />
      </Fade>

      <Fade bottom>
        <CallMe />
      </Fade>

      <Fade bottom>
        <Location title="Localização" />
      </Fade>
    </S.HomeWrapper>
  );
}
