import React from 'react';

import * as S from './styled';

export default function Featured() {
  return (
    <S.FeaturedWrapper>
      <S.FeaturedContent>
        <S.FeaturedCard color="#061943">
          <S.FeaturedCardHeader>
            <S.FeaturedCardTitle>Nossos Parceiros</S.FeaturedCardTitle>
          </S.FeaturedCardHeader>
          <S.FeaturedDescription>
            Tenha acesso a uma ampla rede de empresas credenciadas com descontos
            exclusivos para associados que chegam até 60%.
          </S.FeaturedDescription>
        </S.FeaturedCard>

        <S.FeaturedCard color="#5abecd">
          <S.FeaturedCardHeader>
            <S.FeaturedCardTitle>Conheça os Benefícios</S.FeaturedCardTitle>
          </S.FeaturedCardHeader>
          <S.FeaturedDescription>
            Economize muito nos cuidados com a saúde, nas compras no comércio
            local e divirta-se no melhor clube da região.
          </S.FeaturedDescription>
        </S.FeaturedCard>

        <S.FeaturedCard color="#494b5f">
          <S.FeaturedCardHeader>
            <S.FeaturedCardTitle>Horários de Atendimento</S.FeaturedCardTitle>
          </S.FeaturedCardHeader>
          <S.FeaturedContact>
            <S.FeaturedDescription>
              Segunda a Sexta: 08hs às 18hs
            </S.FeaturedDescription>
            <S.FeaturedDescription>Sábado: 08hs às 12hs</S.FeaturedDescription>
          </S.FeaturedContact>
        </S.FeaturedCard>
      </S.FeaturedContent>
    </S.FeaturedWrapper>
  );
}
