import styled from 'styled-components';
import media from 'styled-media-query';

export const LocationWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3rem 0 0;
`;

export const LocationTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const LocationTitle = styled.h2`
  font-size: 4rem;
  color: #061943;

  ${media.lessThan('small')`
    font-size: 2.5rem;
  `}
`;
