import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'styled-media-query';

export const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  width: 100%;
`;

export const AboutImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`;

export const AboutImageDescription = styled.img`
  width: 35rem;
  max-width: 40rem;
  max-height: 40rem;

  ${media.lessThan('medium')`
    width: 30rem;
  `}

  ${media.lessThan('small')`
    width: 25rem;
  `}
`;

export const AboutDescription = styled.p`
  color: #333;
  font-size: 1.6rem;
  margin: 1.5rem 0;

  ${media.lessThan('medium')`
    font-size: 1.4rem;
  `}
`;

export const AboutHighlight = styled.span`
  color: #222;
  font-weight: bold;
`;

export const AboutLink = styled(Link)`
  color: var(--highlight);
  font-weight: bold;
`;
