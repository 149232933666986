import styled from 'styled-components';
import media from 'styled-media-query';

export const BenefitsWrapper = styled.section`
  display: flex;
  margin: 2rem 0;
`;

export const BenefitsDescription = styled.p`
  color: #333;
  font-size: 1.6rem;
  margin: 1rem 0;
`;

export const BenefitsContent = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 5rem 1rem;

  ${media.lessThan('large')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.lessThan('medium')`
    grid-template-columns: repeat(1, 1fr);
  `}
`;

export const BenefitsCard = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 3rem;
  width: 100%;
  border: none;
  flex-direction: column;
  justify-content: center;

  background-color: #fff;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-top: 0.3rem solid var(--highlight);
  box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.16);
`;

export const BenefitsCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BenefitsCardTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 1rem;
  color: #13304f;
`;

export const BenefitsHighlight = styled.span`
  color: #222;
  font-weight: bold;
`;

export const BenefitsHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
`;

export const BenefitsTitle = styled.h2`
  font-size: 3.5rem;
  color: #13304f;

  ${media.lessThan('small')`
    font-size: 2.2rem;
  `}
`;
