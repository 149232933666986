import styled from 'styled-components';
import media from 'styled-media-query';

export const CallMeWrapper = styled.section`
  display: flex;
  background: #5abecd;
  width: 100%;
  margin: 0 0 3rem;
  align-items: center;
  justify-content: center;
`;

export const CallMeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 5rem 0;

  ${media.lessThan('small')`
    padding: 1rem;
  `}
`;

export const CallMeDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  /* ${media.lessThan('medium')`
    align-items: center;
    justify-content: center;
  `} */

  ${media.lessThan('small')`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const CallMeDescription = styled.h2`
  font-size: 3rem;
  font-weight: bold;
  color: #061943;
  margin-right: 1rem;

  ${media.lessThan('large')`
    font-size: 2.2rem;
  `}

  ${media.lessThan('medium')`
    font-size: 1.8rem;
  `}

  ${media.lessThan('small')`
    font-size: 1.4rem;
  `}
`;

export const CallMePhone = styled.a`
  font-size: 2.4rem;
  font-weight: bold;
  color: #061943;
  text-decoration: none;
  margin-right: 1rem;

  &:hover {
      color: #061943;
      opacity: 0.7;
    }

  ${media.lessThan('large')`
    font-size: 1.8rem;
  `}

  ${media.lessThan('medium')`
    font-size: 1.2rem;
  `}

  ${media.lessThan('small')`
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  `}
`;
