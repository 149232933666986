import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Routes';

import Home from '../pages/Home';
import About from '../pages/About';
import Partner from '../pages/Partner';
import Contact from '../pages/Contact';
import Benefits from '../pages/Benefits';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />

      <Route path="/quem-somos" component={About} />
      <Route path="/nossos-parceiros" component={Partner} />
      <Route path="/beneficios" component={Benefits} />
      <Route path="/fale-conosco" component={Contact} />
    </Switch>
  );
}
