import styled from 'styled-components';
import media from 'styled-media-query';

export const FeaturedWrapper = styled.section`
  display: flex;
  width: 95%;
  margin: 1rem auto;
`;

export const FeaturedContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  ${media.lessThan('medium')`
    grid-template-columns: repeat(1, 1fr);
  `}
`;

export const FeaturedCard = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3rem;
  width: 100%;
  border: none;
  flex-direction: column;
  justify-content: center;

  background: ${props => (props.color ? props.color : '#fff')};
  opacity: 0.9;

  height: 25rem;

  ${media.lessThan('small')`
    height: 20rem;
  `}
`;

export const FeaturedCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeaturedCardHeaderIcon = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-right: 2rem;

  ${media.lessThan('small')`
    width: 3rem;
    height: 3rem;
  `}
`;

export const FeaturedCardTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;

  ${media.lessThan('medium')`
    font-size: 1.9rem;
  `}

  ${media.lessThan('small')`
    font-size: 1.6rem;
  `}
`;

export const FeaturedDescription = styled.p`
  color: #fff;
  font-size: 1.6rem;
  margin-top: 2rem;

  ${media.lessThan('small')`
    font-size: 1.3rem;
  `}
`;

export const FeaturedContact = styled.div`
  display: flex;
  flex-direction: column;
`;
