import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Obfuscate from 'react-obfuscate';

import Location from '../../components/Location';

import {
  Container,
  ContainerHeader,
  ContainerTitle,
} from '../../components/Container/styled';

import * as S from './styled';

export default function Contact() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Container>
        <Helmet>
          <title>Fale conosco | Dr. Opera</title>
          <meta
            name="description"
            content="Fale com a equipe de atendimento Dr. Opera e saiba como adquirir o seu Cartão Multibenefícios. Agende uma visita com um dos nossos consultores."
          />
        </Helmet>

        <ContainerHeader>
          <ContainerTitle>Fale conosco</ContainerTitle>
        </ContainerHeader>

        <S.ContactWrapper>
          <S.ContactColumn>
            <S.ContactContent>
              <S.WhatsappIcon size={36} />
              <S.ContactTitle>Telefone / Whatsapp</S.ContactTitle>
              <S.ContactDescription>(74) 3641-3559</S.ContactDescription>
              <S.ContactDescription>
                <a href="https://api.whatsapp.com/send?phone=5574999417660&text=Quero%20mais%20informações%20sobre%20o%20Cartão%20Dr.%20Opera.">
                  (74) 9.9941-7660
                </a>
              </S.ContactDescription>
              <S.ContactDescription>
                <a href="https://api.whatsapp.com/send?phone=5574999420463&text=Quero%20mais%20informações%20sobre%20o%20Cartão%20Dr.%20Opera.">
                  (74) 9.9942-0463
                </a>
              </S.ContactDescription>
              {/* <S.ContactDescription>(74) 9.9942-0463</S.ContactDescription> */}
            </S.ContactContent>

            <S.ContactContent>
              <S.MapIcon size={36} />
              <S.ContactTitle>Endereço</S.ContactTitle>
              <S.ContactDescription>
                Av. Adolfo Moitinho, 664 - Centro - Irecê - Ba
              </S.ContactDescription>
            </S.ContactContent>

            <S.ContactContent>
              <S.EmailIcon size={36} />
              <S.ContactTitle>Email</S.ContactTitle>
              <S.ContactDescription>
                <Obfuscate
                  email="contato@dropera.com.br"
                  headers={{
                    subject: 'Enviado do site Dr. Opera',
                    body:
                      'Olá! Quero falar com um consultor de vendas. Entre em contato comigo!',
                  }}
                />
              </S.ContactDescription>
            </S.ContactContent>
          </S.ContactColumn>
        </S.ContactWrapper>
      </Container>

      <Location title="Localização" />
    </>
  );
}
