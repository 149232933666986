import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

export const PartnerWrapper = styled.section`
  display: flex;
  margin: 2rem 0;
`;

export const PartnerDescription = styled.p`
  color: #333;
  font-size: 1.6rem;
  line-height: 1.7;
  margin: 1rem 0;

  ${media.lessThan('small')`
    font-size: 1.4rem;
  `}
`;

export const PartnerContent = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 5rem 1rem;

  ${media.lessThan('large')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.lessThan('medium')`
    grid-template-columns: repeat(1, 1fr);
  `}
`;

export const PartnerCard = styled.div`
  display: flex;
  padding: 2rem;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  background-color: #fff;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-top: 0.3rem solid var(--highlight);
  box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.16);
`;

export const PartnerCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PartnerCardTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 1rem;
  color: #13304f;

  ${media.lessThan('small')`
    font-size: 2rem;
  `}
`;

export const PartnerHighlight = styled.span`
  color: #222;
  font-weight: bold;
`;

export const PartnerCTA = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`;

export const PartnerLink = styled(Link)`
  color: var(--highlight);
  font-weight: bold;
`;

export const PartnerImageLogo = styled.img`
  width: 25rem;

  ${media.lessThan('medium')`
    width: 20rem;
  `}
`;
