import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import media from 'styled-media-query';

import image320 from '../../../assets/images/320px.png';
import image768 from '../../../assets/images/768px.png';
import image1024 from '../../../assets/images/1024px.png';

export const BannerWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 95%;
`;

export const BannerImage = styled.div`
  height: 20rem;
  width: 100%;

  border-radius: 0.5rem;

  background-image: url(${image1024});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;

  ${media.lessThan('huge')`
    height: 40rem;
    background-image: url(${image1024});
  `}

  ${media.lessThan('large')`
    height: 40rem;
    background-image: url(${image1024});
  `}

  ${media.lessThan('medium')`
    height: 30rem;
    background-image: url(${image768});
  `}

  ${media.lessThan('small')`
    height: 20rem;
    background-image: url(${image320});
  `}
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;

  height: 100%;
`;

export const BannerTitle = styled.h1`
  font-size: 3.5rem;
  color: #061943;
  font-weight: bold;

  ${media.lessThan('large')`
    font-size: 2.8rem;
  `}

  ${media.lessThan('medium')`
    font-size: 2.2rem;
  `}

  ${media.lessThan('small')`
    font-size: 1.4rem;
  `}
`;

export const BannerTextDescription = styled.p`
  font-size: 2.4rem;
  color: #333;
  margin-top: 1rem;

  ${media.lessThan('large')`
    font-size: 1.8rem;
  `}

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}

  ${media.lessThan('small')`
    font-size: 1.2rem;
  `}
`;

export const BannerCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const BannerCard = styled.img`
  width: 30rem;

  ${media.lessThan('medium')`
    width: 22rem;
  `}

  ${media.lessThan('small')`
    width: 14rem;
  `}
`;

export const BannerButtonCTA = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 20rem;
  background: #f53859;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 3rem;
  transition: background 0.2s;
  margin-top: 4rem;

  padding: 1.5rem 3rem;

  &:hover {
    background: ${darken(0.08, '#f53859')};
  }

  ${media.lessThan('medium')`
    font-size: 1.4rem;
    height: 4rem;
    width: 16rem;
  `}
`;
