import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

import {
  Container,
  ContainerHeader,
  ContainerTitle,
} from '../../components/Container/styled';

import * as S from './styled';

import atlanticoImage from '../../assets/images/clube-espaco-atlantico.png';
import clinicaSIMImage from '../../assets/images/logo-clinica-sim.png';

export default function Partner() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <Helmet>
        <title>Parceiros | Dr. Opera</title>
        <meta
          name="description"
          content="Conheça os parceiros do Cartão Multibenefícios Dr. Opera. Uma ampla rede de empresas credenciadas que atende o associado com preços diferenciados."
        />
      </Helmet>

      <ContainerHeader>
        <ContainerTitle>Nossos parceiros</ContainerTitle>
      </ContainerHeader>

      <S.PartnerWrapper>
        <S.PartnerDescription>
          As empresas credenciadas como parceiras do{' '}
          <S.PartnerHighlight>
            Cartão Multibenefícios Dr. Opera
          </S.PartnerHighlight>{' '}
          proporcionam aos nossos associados, vantagens exclusivas e preços
          diferenciados em produtos e serviços. Conheça alguns dos nossos
          parceiros:
        </S.PartnerDescription>
      </S.PartnerWrapper>

      <S.PartnerContent>
        <S.PartnerCard>
          <S.PartnerCardHeader>
            <S.PartnerCardTitle>MULTICLIN</S.PartnerCardTitle>
          </S.PartnerCardHeader>

          <S.PartnerDescription>
            Hospital de cirurgia MULTICLIN com sua grande estrutura, 02 centros
            cirúrgicos, apartamentos estruturados, conta com o grande cirurgião
            renomado da região Dr. Amauri Lucena.
          </S.PartnerDescription>
        </S.PartnerCard>

        <S.PartnerCard>
          <S.PartnerCardHeader>
            <S.PartnerImageLogo src={clinicaSIMImage} alt="Clínica SIM" />
            <S.PartnerCardTitle>Clínica SIM</S.PartnerCardTitle>
          </S.PartnerCardHeader>

          <S.PartnerDescription>
            A Clínica SIM está pronta para atender associados do Cartão
            Multibenefícios Dr. Opera, assim como atendimento particulares,
            contendo 08 consultórios e a referência de um grande cirurgião
            renomado da região, Dr. Rafael Barreto.
          </S.PartnerDescription>
        </S.PartnerCard>

        <S.PartnerCard>
          <S.PartnerCardHeader>
            <S.PartnerImageLogo
              src={atlanticoImage}
              alt="Clube Espaço Atlântico"
            />
            <S.PartnerCardTitle>Clube Espaço Atlântico</S.PartnerCardTitle>
          </S.PartnerCardHeader>

          <S.PartnerDescription>
            Melhor clube da região oferece ao associado, ambiente familiar,
            piscina para crianças e adultos, piscina com cascata, campo de
            futebol, lanchonete, restaurante, ambiente climatizado, conforto,
            qualidade e segurança.
          </S.PartnerDescription>
        </S.PartnerCard>
      </S.PartnerContent>

      <S.PartnerCTA>
        <S.PartnerDescription>
          É dono de empresa e quer ser parceiro do{' '}
          <S.PartnerHighlight>
            Cartão Multibenefícios Dr. Opera
          </S.PartnerHighlight>
          ? <S.PartnerLink to="/fale-conosco">Fale Conosco</S.PartnerLink>!
        </S.PartnerDescription>
      </S.PartnerCTA>
    </Container>
  );
}
