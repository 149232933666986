import React from 'react';
import PropTypes from 'prop-types';

import GlobaStyles from '../../styles/global';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import * as S from './styled';

export default function Layout({ children }) {
  return (
    <S.LayoutWrapper>
      <Header />
      {children}
      <Footer />
      <GlobaStyles />
    </S.LayoutWrapper>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
