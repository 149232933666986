import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import media from 'styled-media-query';

export const HeaderWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7rem;
  min-width: 100%;
  width: 100%;
  background: var(--backgroundHeader);
  color: var(--text);
  margin-bottom: 2rem;
  padding: 3rem 0;
  opacity: 0.9;
  border-bottom: 0.3rem solid #050f26;
`;

export const HeaderLogoLink = styled(NavLink)`
  margin: 0 0.5rem;

  ${media.lessThan('small')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `}
`;

export const HeaderLogo = styled.img`
  height: 5rem;
  margin-left: 4rem;

  ${media.lessThan('medium')`
    height: 4rem;
  `}

  ${media.lessThan('small')`
    height: 4rem;
    margin-left: 0;
  `}
`;

export const HeaderMenu = styled.div`
  display: flex;

  ${media.lessThan('small')`
    display: none;
  `}
`;

export const HeaderMenuLink = styled(NavLink).attrs({
  activeStyle: {
    fontWeight: 'bold',
    color: '#5abecd',
  },
})`
  font-size: 1.6rem;
  color: var(--text);
  font-weight: bold;
  margin-right: 2rem;

  &:hover {
    color: var(--highlight);
  }

  ${media.lessThan('medium')`
    font-size: 1.4rem;
  `}

  ${media.lessThan('small')`
    font-size: 1.2rem;
  `}
`;
