import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, NavLink } from 'react-router-dom';

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  background: var(--backgroundFooter);
  height: 20rem;
  align-items: center;
  justify-content: center;
`;

export const FooterCopyright = styled.p`
  color: var(--text);
  font-size: 1.4rem;
  margin-top: 2rem;

  ${media.lessThan('small')`
    font-size: 1.2rem;
  `}
`;

export const FooterAddress = styled.p`
  margin-top: 1rem;
  color: var(--text);
  font-size: 1.4rem;

  ${media.lessThan('small')`
    font-size: 1.2rem;
  `}
`;

export const FooterDocCompany = styled.p`
  margin-top: 1rem;
  color: var(--text);
  font-size: 1rem;
`;

export const FooterLink = styled(Link)`
  font-size: 1.4rem;
  color: var(--text);

  &:hover {
    color: var(--highlight);
  }

  ${media.lessThan('small')`
    font-size: 1.2rem;
  `}
`;

export const FooterMenu = styled.div`
  display: flex;
  margin: 0 2rem;
`;

export const FooterMenuLink = styled(NavLink).attrs({
  activeStyle: {
    fontWeight: 'bold',
    color: '#5abecd',
  },
})`
  font-size: 1.4rem;
  color: var(--text);
  padding: 0 1rem;

  &:not(:last-child) {
    border-right: 0.1rem solid #999;
  }

  &:hover {
    color: var(--highlight);
  }

  ${media.lessThan('medium')`
    font-size: 1.2rem;
  `}
`;
