import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import YouTube from 'react-youtube-embed';

import {
  Container,
  ContainerHeader,
  ContainerTitle,
} from '../../components/Container/styled';

import * as S from './styled';

export default function Benefits() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <Helmet>
        <title>Benefícios | Dr. Opera</title>
        <meta
          name="description"
          content="Associados do Cartão Multibenefícios Dr. Opera conta com uma ampla rede de parceiros oferecendo produtos e serviços com preços diferenciados. Saiba mais!"
        />
      </Helmet>

      <ContainerHeader>
        <ContainerTitle>Benefícios</ContainerTitle>
      </ContainerHeader>

      <S.BenefitsWrapper>
        <S.BenefitsDescription>
          Associado do{' '}
          <S.BenefitsHighlight>
            Cartão Multibenefícios Dr. Opera
          </S.BenefitsHighlight>{' '}
          conta com uma ampla rede de parceiros credenciados oferecendo produtos
          e serviços com uma tabela de preços diferenciada. Descontos reais que
          chegam até 60%! Benefícios EXCLUSIVOS aos associados.
        </S.BenefitsDescription>
      </S.BenefitsWrapper>

      <S.BenefitsContent>
        <S.BenefitsCard>
          <S.BenefitsCardHeader>
            <S.BenefitsCardTitle>Aréa de Saúde</S.BenefitsCardTitle>
          </S.BenefitsCardHeader>

          <S.BenefitsDescription>
            Descontos que chegam até 60% em consultas de inúmeras
            especialidades, como também em cirurgias eletivas, farmácias...
          </S.BenefitsDescription>
        </S.BenefitsCard>

        <S.BenefitsCard>
          <S.BenefitsCardHeader>
            <S.BenefitsCardTitle>Comércio</S.BenefitsCardTitle>
          </S.BenefitsCardHeader>

          <S.BenefitsDescription>
            Associados compram com descontos reais e diferenciados no comércio
            local, economizando muito no dia a dia.
          </S.BenefitsDescription>
        </S.BenefitsCard>

        <S.BenefitsCard>
          <S.BenefitsCardHeader>
            <S.BenefitsCardTitle>Esporte</S.BenefitsCardTitle>
          </S.BenefitsCardHeader>

          <S.BenefitsDescription>
            Academias e quadras de esportes credenciadas junto ao Dr. Opera,
            oferecem vantagens exclusivas aos associados.
          </S.BenefitsDescription>
        </S.BenefitsCard>

        <S.BenefitsCard>
          <S.BenefitsCardHeader>
            <S.BenefitsCardTitle>Lazer</S.BenefitsCardTitle>
          </S.BenefitsCardHeader>

          <S.BenefitsDescription>
            Acesso ao maior clube da região de Irecê (Clube Espaço Atlântico)
            para aproveitar momentos inesquecíveis com familiares e amigos.
          </S.BenefitsDescription>
        </S.BenefitsCard>

        <S.BenefitsCard>
          <S.BenefitsCardHeader>
            <S.BenefitsCardTitle>Seguro de Vida</S.BenefitsCardTitle>
          </S.BenefitsCardHeader>

          <S.BenefitsDescription>
            Seguro de vida e sorteios semanais pela loteria federal.
          </S.BenefitsDescription>
        </S.BenefitsCard>

        <S.BenefitsCard>
          <S.BenefitsCardHeader>
            <S.BenefitsCardTitle>Assistência Funeral</S.BenefitsCardTitle>
          </S.BenefitsCardHeader>

          <S.BenefitsDescription>
            Assistência funeral abrangendo o titular, cônjuge e filhos (até 21
            anos).
          </S.BenefitsDescription>
        </S.BenefitsCard>
      </S.BenefitsContent>

      <S.BenefitsHeader>
        <S.BenefitsTitle>Lazer para família</S.BenefitsTitle>
      </S.BenefitsHeader>

      <S.BenefitsWrapper>
        <S.BenefitsDescription>
          Um cartão multibenefícios que vai além dos cuidados com a saúde.
          Aproveite as vantagens de ser um associado do{' '}
          <S.BenefitsHighlight>
            Cartão Multibenefícios Dr. Opera
          </S.BenefitsHighlight>{' '}
          e divirta-se no Clube Espaço Atlâncio. Um lugar perfeito para você e
          sua família.
        </S.BenefitsDescription>
      </S.BenefitsWrapper>

      <YouTube id="2dU4837AOgg" />
    </Container>
  );
}
