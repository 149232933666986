import React from 'react';

import logo from '../../assets/images/logo.png';

import * as S from './styled';

export default function Header() {
  return (
    <S.HeaderWrapper>
      <S.HeaderLogoLink to="/">
        <S.HeaderLogo src={logo} alt="Logomarca Dr. Opera" />
      </S.HeaderLogoLink>

      <S.HeaderMenu>
        <S.HeaderMenuLink to="/quem-somos">Quem somos</S.HeaderMenuLink>
        <S.HeaderMenuLink to="/beneficios">Benefícios</S.HeaderMenuLink>
        <S.HeaderMenuLink to="/nossos-parceiros">Parceiros</S.HeaderMenuLink>
        <S.HeaderMenuLink to="/fale-conosco">Fale conosco</S.HeaderMenuLink>
      </S.HeaderMenu>
    </S.HeaderWrapper>
  );
}
