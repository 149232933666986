import React from 'react';

import cardImage from '../../../assets/images/card.png';

import * as S from './styled';

export default function Banner() {
  return (
    <S.BannerWrapper>
      <S.BannerImage alt="Banner principal">
        <S.BannerContent>
          <S.BannerTitle>Dr. Opera | Cartão Multibenefícios</S.BannerTitle>

          <S.BannerTextDescription>
            O cartão ideal para você e sua família.
          </S.BannerTextDescription>

          <S.BannerCardWrapper>
            <S.BannerCard
              src={cardImage}
              alt="Cartão Multibenefícios Dr. Opera"
            />
          </S.BannerCardWrapper>

          {/* <S.BannerButtonCTA to="/">Peça já o seu</S.BannerButtonCTA> */}
        </S.BannerContent>
      </S.BannerImage>
    </S.BannerWrapper>
  );
}
